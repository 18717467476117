var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled menu-categories",attrs:{"id":"topAccordion"}},[_c('li',{staticClass:"menu single-menu"},[_c('router-link',{staticClass:"dropdown-toggle autodroprown",attrs:{"to":"/"}},[_c('div',{},[_c('i',{staticClass:"fa-light fa-house fa-xl",staticStyle:{"overflow":"unset","margin-right":"14px"}}),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard")))])])])],1),_c('li',{staticClass:"menu single-menu"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"javascript:void(0)","data-toggle":"collapse","aria-expanded":"false"}},[_c('div',{},[_c('i',{staticClass:"fa-light fa-database fa-xl",staticStyle:{"overflow":"unset","margin-right":"14px"}}),_c('span',[_vm._v(_vm._s(_vm.$t("registrations")))])]),_c('svg',{staticClass:"feather feather-chevron-down",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])]),_c('ul',{staticClass:"collapse submenu list-unstyled",attrs:{"id":"datatables","data-parent":"#topAccordion"}},[(_vm.$checkPermission('ver-usuarios'))?_c('router-link',{attrs:{"tag":"li","to":{
          name: 'users',
        }}},[_c('a',[_vm._v("Usuários")])]):_vm._e(),(_vm.$checkPermission('ver-empresas'))?_c('router-link',{attrs:{"tag":"li","to":{
          name: 'companies',
        }}},[_c('a',[_vm._v("Empresas")])]):_vm._e(),_c('router-link',{attrs:{"tag":"li","to":{
          name: 'items',
        }}},[_c('a',[_vm._v("Itens de Contratos")])])],1)]),_c('li',{staticClass:"menu single-menu"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"javascript:void(0)","data-toggle":"collapse","aria-expanded":"false"}},[_vm._m(0),_c('svg',{staticClass:"feather feather-chevron-down",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])]),_c('ul',{staticClass:"collapse submenu list-unstyled",attrs:{"id":"datatables","data-parent":"#topAccordion"}},[_c('router-link',{attrs:{"tag":"li","to":{
          name: 'projects-dashboard',
        }}},[_c('a',[_vm._v("Projetos")])]),_c('router-link',{attrs:{"tag":"li","to":{
          name: 'contracts-dashboard',
        }}},[_c('a',[_vm._v("Contratos")])]),_c('router-link',{attrs:{"tag":"li","to":{
          name: 'activities-dashboard',
        }}},[_c('a',[_vm._v("Atividades")])])],1)]),(_vm.$checkPermission('ver-relatorios'))?_c('li',{staticClass:"menu single-menu"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"javascript:void(0)","data-toggle":"collapse","aria-expanded":"false"}},[_vm._m(1),_c('svg',{staticClass:"feather feather-chevron-down",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])]),_c('ul',{staticClass:"collapse submenu list-unstyled",attrs:{"id":"datatables","data-parent":"#topAccordion"}},[(_vm.$checkPermission('ver-list-activity-progress'))?_c('router-link',{attrs:{"tag":"li","to":{
          name: 'list-activity-progress',
        }}},[_c('a',[_vm._v("Progresso")])]):_vm._e(),(_vm.$checkPermission('ver-performance-report'))?_c('router-link',{attrs:{"tag":"li","to":{
          name: 'performance-report',
        }}},[_c('a',[_vm._v("Desempenho")])]):_vm._e(),(_vm.$checkPermission('ver-performance-report'))?_c('router-link',{attrs:{"tag":"li","to":{
          name: 'monthly-results',
        }}},[_c('a',[_vm._v("Resultado Mensal")])]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('i',{staticClass:"fa-light fa-diagram-project fa-xl",staticStyle:{"overflow":"unset","margin-right":"14px"}}),_c('span',[_vm._v("Projetos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('i',{staticClass:"fa-light fa-diagram-project fa-xl",staticStyle:{"overflow":"unset","margin-right":"14px"}}),_c('span',[_vm._v("Relatórios")])])
}]

export { render, staticRenderFns }