const routes = [
  {
    path: "/reports/activities/progress/list/",
    name: "list-activity-progress",
    component: () =>
      import(/* webpackChunkName: "list-activity-progress" */ "../views/reports/list-activity-progress.vue"),
    meta: { section_name: "reports" },
  },
  {
    path: "/reports/projects/performance/",
    name: "performance-report",
    component: () => import(/* webpackChunkName: "performance-report" */ "../views/reports/performance-report.vue"),
    meta: { section_name: "reports" },
  },
  {
    path: "/reports/project/:projectId",
    name: "project-report",
    component: () =>
      import(/* webpackChunkName: "projects-report" */ "../views/reports/project-performance-report.vue"),
    meta: { section_name: "reports" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
        },
      };
    },
  },
  {
    path: "/reports/monthly-results",
    name: "monthly-results",
    component: () => import(/* webpackChunkName: "monthly-results" */ "../views/reports/monthly-results.vue"),
    meta: { section_name: "reports" },
  },
];
export default routes;
